@import url('https://fonts.cdnfonts.com/css/digital-numbers');

.circle_div {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.parent {
    position: relative;
}

.size {
    position: absolute;
    width: 40%;
    transition: opacity 0.5s ease-in-out;
    transition: width 0.5s ease-in;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard syntax */
}

.center {
    position: absolute;
    width: 35.833%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rotate-0 {
    top: 10.888%;
    left: 30%;
}

.rotate-60 {
    left: 53.6%;
    top: 24.5%;
    transform: rotate(60deg);
}

.rotate-120 {
    top: 51.5%;
    left: 53.6%;
    transform: rotate(120deg);
}

.rotate-180 {
    top: 65%;
    left: 30%;
    transform: rotate(180deg);
}

.rotate-240 {
    top: 51.6%;
    left: 6.6%;
    transform: rotate(240deg);
}

.rotate-300 {
    top: 24.4%;
    left: 6.6%;
    transform: rotate(300deg);
}

.f_width {
    width: 100%;
}

.opacity-0 {
    opacity: 0;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.p1 {
    top: -15%;
    left: 35.1%;
    text-align: center;
    z-index: 1;
    animation: fadeInUp 0.5s ease forwards;
    opacity: 1;
}

.p2 {
    top: 6%;
    left: 97%;
    z-index: 1;
    animation: fadeInUp 0.5s ease forwards;
    opacity: 1;
}

.p3 {
    top: 67%;
    left: 97%;
    z-index: 1;
    animation: fadeInUp 0.5s ease forwards;
    opacity: 1;
}

.p4 {
    top: 91%;
    left: 35.1%;
    text-align: center;
    z-index: 1;
    animation: fadeInUp 0.5s ease forwards;
    opacity: 1;
}

.p5 {
    top: 67%;
    left: -23%;
    text-align: end;
    z-index: 1;
    animation: fadeInUp 0.5s ease forwards;
    opacity: 1;

}

.p6 {
    top: 6%;
    left: -23%;
    text-align: end;
    z-index: 1;
    animation: fadeInUp 0.5s ease forwards;
    opacity: 1;
}

.textSize {
    position: absolute;
    width: 30%;
    opacity: 0;
    text-decoration: none;
    cursor: pointer;
    transition: text-decoration 1s ease, color 1s ease;
}

@media (max-width: 430px) {
    .textSize {
        width: 25%;
    }

    .p2 {
        left: 90%;
    }

    .p3 {
        left: 90%;
    }

    .p5 {
        left: -17%;
    }

    .p6 {
        left: -17%;
    }
}


@media (max-width: 899px) {
    .textSize {
        font-size: 13px;
    }
}

.first_count {
    top: 12%;
    left: 38.1%;
    width: 24%;
    height: 18%;
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
    z-index: 1;
    cursor: pointer;
}

.second_count {
    top: 22%;
    left: 65%;
    width: 18%;
    height: 27%;
    border-bottom-left-radius: 40%;
    border-top-left-radius: 20%;
    border-top-right-radius: 50%;
    z-index: 1;
    cursor: pointer;
}

.third_count {
    top: 51%;
    left: 65%;
    width: 18%;
    height: 27%;
    border-bottom-right-radius: 40%;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 10%;
    z-index: 1;
    cursor: pointer;
}

.fourth_count {
    top: 68%;
    left: 38.1%;
    width: 24%;
    height: 18%;
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
    z-index: 1;
    cursor: pointer;
}

.fifth_count {
    top: 51%;
    left: 17%;
    width: 18%;
    height: 27%;
    border-bottom-right-radius: 40%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    z-index: 1;
    cursor: pointer;
}

.sixth_count {
    top: 22%;
    left: 17%;
    width: 18%;
    height: 27%;
    border-bottom-right-radius: 40%;
    border-top-right-radius: 20%;
    border-top-left-radius: 50%;
    z-index: 1;
    cursor: pointer;
}

.counter_div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter_div p {
    transition: color 1s ease-in-out;
}

.counter_text {
    font-family: Tanker;
    text-align: center;
    font-size: 300%;
    margin: auto;
    z-index: 3;
}

@media (max-width: 901px) {
    .counter_text {
        font-size: 200%;
    }
}

.white {
    color: #EBEBEB;
}

.black {
    color: #3A3A3A;
}

.timer {
    position: absolute;
    width: 35.833%;
    text-align: center;
    font-family: 'Digital Numbers';
    color: #11D991;
    font-size: 60px;
    text-shadow: 0px 0px 25px #30F7AFCC;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.crossed {
    text-decoration: line-through;
    color: #676767;
}

@media (orientation: portrait) and (max-width: 768px) {
    .textSize{
        font-size: 11px;
    }
}