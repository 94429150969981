.rules_title{
    text-align: center;
    padding-top: 35px;
}

.rules_info{
    margin-top: 45px;
    padding: 0px 15% 0px 15%;
}

.info_page_button{
    margin: 40px 25% 8px 25%;
}