.points_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.points_row{
    width: 70%;
    margin: 30px;
    display: flex;
    justify-content: center;
    padding: 12px;
    border-radius: 27px;
    align-items: center;
}

.score_text_board_points{
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-align: center;
}

@media (orientation: portrait) {
    .points_row{
        margin: 18px;
        padding: 7px;
    }
}