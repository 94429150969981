.wheel_container {
    /* width: 400px; */
    width: 50%;
    /* height: 800px; */
}

.wheel_center {
    display: table;
    margin: auto;
}

.lines {
    background-image: url('./img/lines.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.right_content_game {
    display: inline-grid;
    height: 100%;
    justify-content: center;
    justify-items: center;
    overflow: hidden;
    padding: 30px 20px 90px 20px;
}

.timer_line {
    height: 1vh;
    width: 100%;
    background-color: #EBEBEB;
    padding-left: 0;
    z-index: 120;
}

.fixRow {
    padding: 0;
    margin: 0;
    position: relative;
}

.timer_representation {
    height: 100%;
    background-color: #3A3A3A;
    transition: width 0.3s;
}

.whole_wheel {
    height: 100%;
}

.row_wheel {
    height: 100%;
}

.mid_wheel {
    display: flex;
}

.right_container_game {
    padding-left: 0px;
    padding-right: 0px;
}

.question_text {
    font-family: fantasy;
    z-index: 120;
}

.play_text_game_screen {
    margin-top: 120px;
    min-height: 100px;
    z-index: 120;
}

.play_button_game_screen {
    margin-top: 120px;
    z-index: 120;
}

.play_button_transparent {
    background-color: rgba(0, 0, 0, 0);
}

.center_content_game_screen_mobile {
    height: 88vh;
}

.right_content_game_screen_mobile {
    margin: 10px 20px 0px 20px;
}

.wheel_game_part_mobile {
    height: 65%;
}

.button_game_part_mobile {
    height: 35%;
}

.play_text_question {
    font-family: fantasy;
}

.right_container_game_mobile {
    height: 35%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.right_content_up_mobile {
    height: auto;
}

@media only screen and (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    .wheel_container {
        min-width: 300px;
    }

    .center_content_screen {
        padding-top: 0px;
    }

    .right_bot_container {
        margin-top: 0px !important;
    }

    .timer {
        font-size: 50px;
    }

    .mobile_content_screen {
        height: fit-content;
    }

    .left_container_mobile {
        margin: 0px 0px !important;
    }
}

@media only screen and (max-width: 780px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    .left_container {
        margin: 0px 0px !important;
    }

    .right_content_game {
        margin-top: auto;
    }
}

@media (orientation: portrait) {
    .right_content_game_screen_mobile {
        padding: 30px 20px 0px 20px;
    }
}

@media (orientation: portrait) and (max-height: 700px) {
    .right_content_game_screen_mobile {
        padding: 0px 20px 0px 20px;
    }
}

@media (orientation: landscape) and (max-height: 560px) {
    .right_bot_container {
        height: 80vh;
    }

    .play_text_game_screen {
        margin-top: 10px;
    }

    .play_button_game_screen {
        margin-top: 10px;
    }

    .right_content_game {
        padding: 30px 20px 30px 20px;
    }

    .counter_text {
        font-size: 250%;
    }
}

@media (orientation: portrait) and (min-width: 768px) {
    .wheel_game_part_mobile {
        width: 100%;
    }
}