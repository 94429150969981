.range_modal_input_box{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
}
.range_modal_input{
    margin: 15px;
    background-color: #D9D9D9;
    width: 150px;
    height: 40px;
    border-radius: 15px;
    font-family: cursive;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -0.01em;
    text-align: center;
    border: none;
}
.range_modal_button{
    margin: 15px;
    font-weight: 700;
    border-radius: 15px;
    border: solid 1px #3A3A3A;
    background-color: #EBEBEB;
    padding: 5px 0px 5px 0px;
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
}